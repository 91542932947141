export const financeRecordData = [
  {
    details: "Description",
    field: "description",
    existing: " ",
    proposed: " "
  },
  {
    details: "Full name",
    field: "fullName",
    existing: " ",
    proposed: " "
  },
  {
    details: "New/Used",
    field: "newOrUsed",
    existing: " ",
    proposed: " "
  },
  {
    details: "Registration date",
    type: "date",
    field: "registrationDate",
    existing: " ",
    proposed: " "
  },
  {
    details: "Start date",
    type: "date",
    field: "startDate",
    existing: " ",
    proposed: " "
  },
  {
    details: "Start kilometers",
    field: "startKilometers",
    existing: " ",
    proposed: " "
  },
  {
    details: "Start age",
    field: "startAge",
    existing: " ",
    proposed: " "
  },
  {
    details: "License Plate",
    field: "licencePlate",
    existing: " ",
    proposed: " "
  },
  {
    details: "VIN",
    field: "vin",
    existing: " ",
    proposed: " "
  },
  {
    details: "Payments remaining",
    field: "paymentsRemaining",
    existing: " ",
    proposed: " "
  },
  {
    details: "Term/Total Km",
    field: "termOrTotalKm",
    existing: " ",
    proposed: " "
  },
  {
    details: "Term",
    field: "term",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total Kilometers",
    field: "totalKilometers",
    existing: " ",
    proposed: " "
  },
  {
    details: "Finance Type",
    field: "financeTypeName",
    existing: " ",
    proposed: " "
  },
  {
    details: "Interest Rate",
    type: "rate",
    field: "interestRateFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Comparison Rate",
    type: "rate",
    field: "comparisonRateFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Equity",
    field: "equityFormatted",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Amount to match",
    field: "amountToMatch",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Trade-in Value",
    field: "tradeInValueFormatted",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Settlement Value",
    field: "settlementValueFormatted",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Purchase Price",
    field: "purchasePriceFormatted",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total Deposit",
    field: "totalDepositFormatted",
    type: "price",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total manufacturer deposit",
    type: "price",
    field: "totalManufacturerDepositFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total dealer deposit",
    type: "price",
    field: "totalDealerDepositFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total customer deposit",
    type: "price",
    field: "totalCustomerDepositFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Total amount financed",
    type: "price",
    field: "totalAmountFinancedFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Guaranteed Future Value (GFV)",
    type: "price",
    field: "gfvFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Upfront Fees",
    type: "price",
    field: "upfrontFeesFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Monthly payment",
    type: "price",
    field: "monthlyPaymentFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Monthly difference",
    type: "price",
    field: "monthlyDifferenceFormatted",
    existing: " ",
    proposed: " "
  },
  {
    details: "Deposit to match payment/Cashback potential",
    type: "price",
    field: "cashbackFormatted",
    existing: " ",
    proposed: " "
  }
];
