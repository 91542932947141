import "../../styles/customerRecord/deskingSheet.css";
import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import {
  checkMarketAu,
  checkMarketBulgaria,
  checkMarketHK,
  formatCurrency,
} from "../../utils/helpers";

const DeskingSheet = ({
  marketSettings,
  financeData,
  customerRecordData,
  clientPosition,
}) => {
  const { t } = useTranslation();

  let getLanguage = localStorage.getItem("lng");
  const pdfTranslation = `${
    getLanguage === "en"
      ? "en-font"
      : getLanguage === "hk"
      ? "hk-font"
      : getLanguage === "bg"
      ? "bg-font"
      : "en-font"
  }`;
  
  const headerValue = ` ${
    checkMarketBulgaria() ? "bg-font" : checkMarketHK() ? "hk-font" : "en-font"
  }
          `;

  const currencyOptions = {
    symbol: marketSettings.currencySymbol,
    separator: ",",
  };

  const currentVehicleData = [
    {
      details: getTranslation("Brand", t),
      existing: financeData?.existing?.brand,
      proposed: financeData?.proposed?.brand,
    },
    {
      details: getTranslation("Description", t),
      existing: financeData?.existing?.description,
      proposed: financeData?.proposed?.description,
    },
    {
      details: getTranslation("New/Used", t),
      existing: financeData?.existing?.newOrUsed,
      proposed: financeData?.proposed?.newOrUsed,
    },
    {
      details: getTranslation("Registration date", t),
      existing: financeData?.existing?.registrationDate
        ? format(
            new Date(financeData?.existing?.registrationDate),
            "dd/MM/yyyy"
          )
        : null,
      proposed: financeData?.proposed?.registrationDate
        ? format(
            new Date(financeData?.proposed?.registrationDate),
            "dd/MM/yyyy"
          )
        : null,
    },
    {
      details: getTranslation("Start date", t),
      existing: financeData?.existing?.startDate
        ? format(new Date(financeData?.existing?.startDate), "dd/MM/yyyy")
        : null,
      proposed: "N/A",
    },
    {
      details: getTranslation("Start kilometers", t),
      existing:
        financeData?.existing?.startKilometers === null
          ? "0 km"
          : `${financeData?.existing?.startKilometers} km`,
      proposed: financeData?.proposed?.startKilometers,
    },
  ];

  const currentFinanceData = [
    {
      details: getTranslation("Finance Type", t),
      existing: financeData?.existing?.financeTypeName,
      proposed: financeData?.proposed?.financeTypeName,
    },
    {
      details: getTranslation("Purchased Price", t),
      existing: financeData?.existing?.purchasePriceFormatted
        ? formatCurrency(
            financeData?.existing?.purchasePriceFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.purchasePriceFormatted
        ? `${formatCurrency(
            financeData?.proposed?.purchasePriceFormatted,
            currencyOptions.symbol
          )}*`
        : null,
    },
    {
      details: getTranslation("Equity", t),
      existing: financeData?.existing?.equityFormatted
        ? formatCurrency(
            financeData?.existing?.equityFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A",
    },
    {
      details: getTranslation("Trade-in Value", t),
      existing: financeData?.existing?.tradeInValueFormatted
        ? formatCurrency(
            financeData?.existing?.tradeInValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A",
    },
    {
      details: getTranslation("Settlement Value", t),
      existing: financeData?.existing?.settlementValueFormatted
        ? formatCurrency(
            financeData?.existing?.settlementValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: "N/A",
    },
    {
      details: getTranslation("Total Deposit", t),
      existing: financeData?.existing?.totalDepositFormatted
        ? formatCurrency(
            financeData?.existing?.totalDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : "-",
      proposed: financeData?.proposed?.totalDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : formatCurrency(0, currencyOptions.symbol),
    },
    {
      details: getTranslation("Total manufacturer deposit", t),
      existing: "-",
      proposed: financeData?.proposed?.totalManufacturerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalManufacturerDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : formatCurrency(0, currencyOptions.symbol),
    },
    {
      details: getTranslation("Total dealer deposit", t),
      existing: "-",
      proposed: financeData?.proposed?.totalDealerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalDealerDepositFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : formatCurrency(0, currencyOptions.symbol),
    },
    {
      details: getTranslation("Customer deposit (Cash)", t),
      existing: "-",
      proposed:
        financeData?.proposed?.totalCustomerDepositFormatted || 0
          ? formatCurrency(
              financeData?.proposed?.totalCustomerDepositFormatted,
              currencyOptions.symbol,
              currencyOptions.separator
            )
          : formatCurrency(0, currencyOptions.symbol),
    },
    {
      details: getTranslation("Total customer deposit", t),
      existing: "N/A",
      proposed: financeData?.proposed?.totalCustomerDepositFormatted
        ? formatCurrency(
            financeData?.proposed?.totalCustomerDepositFormatted,
            currencyOptions.symbol
          )
        : formatCurrency(0, currencyOptions.symbol),
    },
    {
      details: getTranslation("Total amount financed", t),
      existing: financeData?.existing?.totalAmountFinancedFormatted
        ? formatCurrency(
            financeData?.existing?.totalAmountFinancedFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.totalAmountFinancedFormatted
        ? `${formatCurrency(
            financeData?.proposed?.totalAmountFinancedFormatted,
            currencyOptions.symbol
          )}*`
        : null,
    },
    {
      details: getTranslation("Interest rate", t),
      existing: financeData?.existing?.interestRateFormatted
        ? `${financeData?.existing?.interestRateFormatted}%`
        : null,
      proposed: financeData?.proposed?.interestRateFormatted
        ? `${financeData?.proposed?.interestRateFormatted}%`
        : null,
    },
    {
      details: getTranslation("Comparison Rate", t),
      existing: financeData?.existing?.comparisonRate
        ? financeData?.existing?.comparisonRateFormatted + "%"
        : financeData?.existing?.comparisonRate === 0
        ? "0%"
        : "N/A",
      proposed: financeData?.proposed?.comparisonRate
        ? financeData?.proposed?.comparisonRateFormatted + "%"
        : financeData?.proposed?.comparisonRate === 0
        ? "0%"
        : "N/A",
    },
    {
      details: getTranslation("Term", t),
      existing: financeData?.existing?.term,
      proposed: financeData?.proposed?.term,
    },
    {
      details: getTranslation("Monthly payment", t),
      existing: financeData?.existing?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.existing?.monthlyPaymentFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.monthlyPaymentFormatted
        ? formatCurrency(
            financeData?.proposed?.monthlyPaymentFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
    },
    {
      details: getTranslation("Guaranteed Future Value (GFV)", t),
      existing: financeData?.existing?.gfvFormatted
        ? formatCurrency(
            financeData?.existing?.gfvFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
      proposed: financeData?.proposed?.gfvFormatted
        ? formatCurrency(
            financeData?.proposed?.gfvFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
    },
    {
      details: getTranslation("Upfront Fees", t),
      existing: financeData?.existing?.upfrontFeesFormatted
        ? formatCurrency(
            financeData?.existing?.upfrontFeesFormatted,
            currencyOptions.symbol
          )
        : null,
      proposed: financeData?.proposed?.upfrontFeesFormatted
        ? formatCurrency(
            financeData?.proposed?.upfrontFeesFormatted,
            currencyOptions.symbol
          )
        : null,
    },
    {
      details: getTranslation("Months Elapsed", t),
      existing: financeData?.existing?.monthsElapsed,
      proposed: "",
    },
    {
      details: getTranslation("Projected end date", t),
      existing: financeData?.existing?.contractEndDate
        ? format(new Date(financeData?.existing?.contractEndDate), "dd/MM/yyyy")
        : null,
      proposed: "",
    },
    {
      details: getTranslation("Payments left", t),
      existing: financeData?.existing?.paymentsRemaining,
      proposed: "",
    },
  ];

  const equityCalculationData = [
    {
      details: getTranslation("Part Exchange Value", t),
      existing: financeData?.existing?.tradeInValueFormatted
        ? `${formatCurrency(
            financeData?.existing?.tradeInValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )}*`
        : null,
    },
    {
      details: getTranslation("Assumed Current Mileage", t),
      existing: financeData?.existing?.lastMileageFromServiceSystem,
    },
    {
      details: getTranslation("Settlement figure", t),
      existing: financeData?.existing?.settlementValueFormatted
        ? formatCurrency(
            financeData?.existing?.settlementValueFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )
        : null,
    },
    {
      details: getTranslation("Equity", t),
      existing: financeData?.existing?.equityFormatted
        ? `${formatCurrency(
            financeData?.existing?.equityFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )}*`
        : null,
    },
  ];

  const costData = [
    {
      details: getTranslation("Monthly Cost/Saving", t),
      existing: financeData?.proposed?.monthlyDifferenceFormatted
        ? `(${formatCurrency(
            financeData?.proposed?.monthlyDifferenceFormatted,
            currencyOptions.symbol,
            currencyOptions.separator
          )})`
        : null,
    },
  ];

  // Add styling on table rows
  const rowRender = (trElement, props) => {
    const trProps = {
      style: {
        backgroundColor: "#ffff",
        color: "#00233AE5",
      },
    };

    const tdProps = {
      style: {
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 1,
        height: 57,
      },
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children.map((tdElement) => {
        return React.cloneElement(
          tdElement,
          {
            ...tdProps,
          },
          tdElement.props.children
        );
      })
    );
  };

  //Render table row
  const rowCell = (props) => {
    const { dataItem, field } = props;
    const { details } = dataItem;
    const newUsedDetails =
      details === getTranslation("Registration date", t) ||
      details === getTranslation("Start date", t) ||
      details === getTranslation("Start kilometers", t) ||
      details === getTranslation("Start age", t);
    const equityDetails =
      details === getTranslation("Trade-in Value", t) ||
      details === getTranslation("Settlement Value", t);
    const totalDepositDetails =
      details === getTranslation("Total manufacturer deposit", t) ||
      details === getTranslation("Total dealer deposit", t) ||
      details === getTranslation("Total customer deposit", t) ||
      details === getTranslation("Customer deposit (Cash)", t);

    if (
      (details === getTranslation("Upfront Fees", t) ||
        details === getTranslation("Comparison Rate", t) ||
        details === getTranslation("Total customer deposit", t)) &&
      !checkMarketAu()
    ) {
      return;
    }

    return (
      //add rows to hide
      <td
        className={
          newUsedDetails || equityDetails || totalDepositDetails
            ? `${"rowCell"} ${"expandedDetails"}`
            : "rowCell"
        }
      >
        <div
          className={
            details === getTranslation("Monthly payment", t) &&
            field !== "existing"
              ? details === getTranslation("Monthly payment", t) &&
                field === "details"
                ? `row-highlight row-highlight-label ${pdfTranslation}-bold`
                : `row-highlight ${pdfTranslation}-bold`
              : details === getTranslation("Monthly payment", t) &&
                field === "existing"
              ? `monthly-payment-amount ${pdfTranslation}-bold`
              : field === "proposed"
              ? "proposed-row-item"
              : field === "details" &&
                (newUsedDetails || equityDetails || totalDepositDetails)
              ? "expanded-details-row"
              : "row-item"
          }
        >
          {field === "details" ? dataItem[field] : dataItem[field]}
        </div>
      </td>
    );
  };

  const HeaderLabel = ({ label }) => {
    const labelClasses = {
      Proposed: "header-row-highlight-label",
      Existing: "header-row-highlight-label-existing",
      Details: "header-row-highlight-label-details",
    };

    return labelClasses[label] ? (
      <span className="k-cell-inner">
        <span className="k-link !k-cursor-default">
          <span className={`${labelClasses[label]} ${pdfTranslation}-bold`}>
            {getTranslation(label, t)}
          </span>
        </span>
      </span>
    ) : null;
  };

  return (
    <>
      <p className={`pdf-title ${pdfTranslation}-bold`}>
        {getTranslation("RETENTION ENGINE INTERNAL USE ONLY", t)}
      </p>
      <div className="pdf-header-container">
        <div className="pdf-header-container-wrapper">
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Dealer", t)}:
          </p>
          <p className={`pdf-header-container-value ${headerValue}`}>
            {!customerRecordData.sellingDealer
              ? "-"
              : getTranslation(customerRecordData.sellingDealer, t)}
          </p>
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Campaign", t)}:
          </p>
          <p className={`pdf-header-container-value ${pdfTranslation}`}>
            {getTranslation(clientPosition.campaignName, t)}
          </p>
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Generation date", t)}:
          </p>
          <p className={`pdf-header-container-value ${headerValue}`}>
            {format(new Date(), "dd/MM/yyyy")}
          </p>
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Customer name", t)}:
          </p>
          <p className={`pdf-header-container-value ${headerValue}`}>
            {!customerRecordData.customerName
              ? "-"
              : customerRecordData.customerName}
          </p>
        </div>
        <div className="pdf-header-container-wrapper">
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Preferred contact number", t)}:
          </p>
          <p className={`pdf-header-container-value ${headerValue}`}>
            {!customerRecordData.customerPhone
              ? "-"
              : customerRecordData.customerPhone}
          </p>
          <p className={`pdf-header-container-label ${pdfTranslation}-bold`}>
            {getTranslation("Alternative contact number", t)}:
          </p>
          <p className={`pdf-header-container-value ${headerValue}`}>
            {!customerRecordData.AlternativeNumber
              ? "-"
              : customerRecordData.AlternativeNumber}
          </p>
        </div>
      </div>
      <div className={`pdf-grid-vehicle-details ${pdfTranslation}`}>
        <p className={`pdf-title-common ${pdfTranslation}-bold`}>
          {getTranslation("Current vehicle details", t)}:
        </p>
        <Grid data={currentVehicleData} rowRender={rowRender}>
          <GridColumn
            cell={rowCell}
            title={getTranslation("Details", t)}
            field="details"
            headerCell={() => <HeaderLabel label="Details" />}
          />
          <GridColumn
            cell={rowCell}
            title={getTranslation("Existing", t)}
            field="existing"
            headerCell={() => <HeaderLabel label="Existing" />}
          />
          <GridColumn
            cell={rowCell}
            title={getTranslation("Proposed", t)}
            field="proposed"
            headerCell={() => <HeaderLabel label="Proposed" />}
          />
        </Grid>
      </div>
      <div className={`pdf-grid-finance-details ${pdfTranslation}`}>
        <p className={`pdf-title-common ${pdfTranslation}-bold`}>
          {getTranslation("Current Finance details", t)}:
        </p>
        <Grid data={currentFinanceData} rowRender={rowRender}>
          <GridColumn
            cell={rowCell}
            title="Details"
            field="details"
            headerCell={() => <HeaderLabel label="Details" />}
          />
          <GridColumn
            cell={rowCell}
            title="Existing"
            field="existing"
            headerCell={() => <HeaderLabel label="Existing" />}
          />
          <GridColumn
            cell={rowCell}
            title="Proposed"
            field="proposed"
            headerCell={() => <HeaderLabel label="Proposed" />}
          />
        </Grid>
      </div>
      <div className={`pdf-grid-two-col ${pdfTranslation}`}>
        <div>
          <p className={`pdf-title-common ${pdfTranslation}-bold`}>
            {getTranslation("Equity Calculation", t)}:
          </p>
          <Grid data={equityCalculationData} rowRender={rowRender}>
            <GridColumn
              cell={rowCell}
              title="Details"
              field="details"
              headerCell={() => <HeaderLabel label="Details" />}
            />
            <GridColumn
              cell={rowCell}
              title="Existing"
              field="existing"
              headerCell={() => <HeaderLabel label="Existing" />}
            />
          </Grid>
        </div>

        <div>
          <p className={`pdf-title-common ${pdfTranslation}-bold`}>
            {getTranslation("Cost - Saving to Change", t)}:
          </p>
          <Grid data={costData} rowRender={rowRender}>
            <GridColumn
              cell={rowCell}
              title="Details"
              field="details"
              headerCell={() => <HeaderLabel label="Details" />}
            />
            <GridColumn
              cell={rowCell}
              title="Existing"
              field="existing"
              headerCell={() => <HeaderLabel label="Existing" />}
            />
          </Grid>
        </div>
      </div>
      <div className="pdf-grid-title-footer">
        <p className={`pdf-grid-title-detail-footer ${pdfTranslation}-bold`}>
          {getTranslation("Contact Date", t)}
        </p>
        <p className={`pdf-grid-title-existing-footer ${pdfTranslation}-bold`}>
          {getTranslation("Comments", t)}
        </p>
        <p className={`pdf-grid-title-detail-footer ${pdfTranslation}-bold`}>
          {getTranslation("F/Up Date", t)}
        </p>
      </div>
      <div className="footer-grid">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className="pdf-grid-title-footer">
        <p className={`pdf-grid-title-detail-footer ${pdfTranslation}-bold`}>
          {getTranslation("Manager Comments & Action Plan", t)}
        </p>
      </div>
    </>
  );
};

export default DeskingSheet;
